import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyD6Ls8Ax6S6qDxJigRG39aIrgXSdik_710",
  authDomain: "myedprep-33ee0.firebaseapp.com",
  projectId: "myedprep-33ee0",
  storageBucket: "myedprep-33ee0.appspot.com",
  messagingSenderId: "577341486066",
  appId: "1:577341486066:web:0bc634fb39483c2710f16b",
  measurementId: "G-W8W259NZ4W"
};

let db = firebase.initializeApp(firebaseConfig);

// return  firebase.messaging();

// .firestore();

// let messaging = firebase.messaging();
// db = db.firestore()

export default db


// export const requestFirebaseNotificationPermission = () =>
//   new Promise((resolve, reject) => {
//     messaging
//       .requestPermission()
//       .then(() => messaging.getToken())
//       .then((firebaseToken) => {
//         resolve(firebaseToken);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });

