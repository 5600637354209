import db from "./firebase";
import 'firebase/messaging';
import { notification } from "antd"
import logoIcon from "../../images/logo_icon.png"

let messaging = db.messaging();
// const REACT_APP_VAPID_KEY = "BGtMxDQSTFE8ljY7M0l2bheFgWaEnN561EOw4CK9yNmVr_SpgKMJY4LUba0qkBibhEYdHFYYOYSybfK_ik7OV_I"
// const publicKey = REACT_APP_VAPID_KEY;


// export const getToken = async (setTokenFound) => {
//   let currentToken = '';
//   try {
//     currentToken = await messaging.getToken({ vapidKey: publicKey });
//     if (currentToken) {
//       setTokenFound(true);
//     } else {
//       setTokenFound(false);
//     }
//   } catch (error) {
//     console.log('An error occurred while retrieving token.', error);
//   }
//   return currentToken;
// };

export const requestFirebaseNotificationPermission = () => {
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => {
        messaging.getToken()
          .then((firebaseToken) => {
            // console.log(firebaseToken , " : firebaseToken")
            localStorage.setItem("fcmToken", firebaseToken)
            resolve(firebaseToken);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

const openNotification = (title, body) => {
  notification.open({
    // message: <label style={{ fontWeight: "bold" }}>New Message in {groupData?.grpData?.groupName} from : {title}</label>,
    message: <label style={{ fontWeight: "bold" }}>New Message from : {title}</label>,
    description: body,
    className: 'custom-class',
    style: {
      width: 500,
    },
    icon: <img src={logoIcon} style={{ height: "20px", marginTop: "20px", marginRight: "20px" }} />,
    // onclick: () => { alert(groupData?.grpData?.groupName) }
  });
};

export const onMessageListener = (_id) => {
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      let user_id = JSON.parse(payload.data.data).user_id
      const { title, body } = payload.data;
      if (String(user_id) != String(_id)) {
        // toast.success(`${title} : ${body}`)
        openNotification(title, body)
      }
      resolve(payload);
    }, e => {
      console.log(e)
    })
  })
}

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });
